<template>
	<div>
		<TopTabBar :hidebelowtabs="true">
			<template v-slot:belowtabs>
			</template>
		</TopTabBar>
		<div class="body-hold hide-below-tabs">
			<div>
				<div v-if="store.selectionObs.markets.length === 0 || store.selectionObs.surveys.length === 0">
					<p>Please choose at least one market and survey first, or upload a schedule using the options below.</p>
				</div>
				<div style="margin: 10px 20px 20px;">
					<div style="display: flex; align-content: center; margin-top: 15px; margin-bottom: 5px;">
						<p class="subheading" style="margin-bottom: 0; display: flex; align-items: center;">
							Schedule Input Type: {{store.rfConfig.type}}.
						</p>
						<div v-if="store.rfConfig.type === 'precampaign'">
							<span style="margin: 0 5px 0 15px; font-weight: normal; font-size: 12px;">Week Starts On</span>
							<select v-model="spotGenerateWeekStartsOn" class="selectbox">
								<option value="sun">Sunday</option>
								<option value="mon">Monday</option>
								<option value="tue">Tuesday</option>
								<option value="wed">Wednesday</option>
								<option value="thu">Thursday</option>
								<option value="fri">Friday</option>
								<option value="sat">Saturday</option>
							</select>

							<span style="margin: 0 5px 0 15px; font-weight: normal; font-size: 12px;" title="By default, generated spot dates are based relative to today.  Change this date if you wish to use actual weeks (set as a date in the first actual week)">
								Start Date
							</span>
							<input type="date" v-model="spotGenerateFirstWeekDate" class="input-dater" style="">
						</div>
					</div>
					<div style="display: flex;">
<!--						<ScheduleUploader v-if="store.rfConfig.type === 'qtrhr'"></ScheduleUploader>-->
						<ScheduleGSCSVUploader v-if="store.rfConfig.type === 'goldstdcsv'"></ScheduleGSCSVUploader>
						<SchedulePostTimesUploader v-if="store.rfConfig.type === 'post_times'"></SchedulePostTimesUploader>
					</div>
				</div>

				<div class="view-holder" v-if="store.selectionObs.markets.length > 0 && store.selectionObs.surveys.length > 0">
					<div v-if="store.rfConfig.type !== 'goldstdcsv' && store.rfConfig.type !== 'post_times'" style="padding-bottom: 10px;">
						<div style="display: flex; gap: 10px; margin-bottom: 20px; align-items: center;">
							<p class="subheading" style="margin-top: 0px; margin-bottom: 5px;">Schedule Summary</p>
<!--							<select v-model="store.rfScheduleDemoId" class="selectbox">-->
<!--								<option v-for="item in store.selectionObs.demographics" :key="item.id" :value="item.id">{{item.name}}</option>-->
<!--							</select>-->
						</div>
						<div style="width: 100%; overflow-x: auto;">
							<table class="std-tbl">
								<thead>
								<tr>
									<td></td>
									<td v-for="(item, index) in store.rfScheduleWeeklySummary" :key="index" class="bold">
										<span>Week {{item.weekNumber}}</span>
									</td>
									<td class="bold">Total</td>
								</tr>
								</thead>
								<tbody>
								<tr>
									<td class="bold">Spots</td>
									<td v-for="(item, index) in store.rfScheduleWeeklySummary" :key="index">
										<span>{{item.nSpots}}</span>
									</td>
									<td>{{store.rfTotalSpots}}</td>
								</tr>
								<tr>
									<td class="bold">Cost</td>
									<td v-for="(item, index) in store.rfScheduleWeeklySummary" :key="index">
										<span>${{numdisplay(item.cost)}}</span>
									</td>
									<td>${{numdisplay(store.rfTotalCost)}}</td>
								</tr>
<!--								<tr>-->
<!--									<td class="bold">Impacts</td>-->
<!--									<td v-for="(item, index) in store.rfScheduleWeeklySummary" :key="index">-->
<!--										<span>{{numdisplay(item.impacts)}}</span>-->
<!--									</td>-->
<!--									<td>{{numdisplay(store.rfTotalImpacts)}}</td>-->
<!--								</tr>-->
<!--								<tr>-->
<!--									<td class="bold">CPM</td>-->
<!--									<td v-for="(item, index) in store.rfScheduleWeeklySummary" :key="index">-->
<!--										<span>${{numdisplayNdp(item.cpm, 2)}}</span>-->
<!--									</td>-->
<!--									<td>${{numdisplayNdp(store.rfTotCpm, 2)}}</td>-->
<!--								</tr>-->
								</tbody>
							</table>
						</div>
					</div>

					<div style="padding: 0 0 10px;">
						<p class="subheading" style="margin-top: 15px; margin-bottom: 5px;">
							<span v-if="store.rfConfig.type !== 'precampaign'" style="margin-right: 20px;">Spot Schedule</span>
							<button v-if="['post_times'].includes(store.rfConfig.type)" @click="sortScheduleByDate">Sort By Date</button>
						</p>
						<RFPreCampaignEditor v-if="store.rfConfig.type === 'precampaign'"
							:weekstartson="spotGenerateWeekStartsOn" :activedate="spotGenerateFirstWeekDate"></RFPreCampaignEditor>
						<p v-if="store.rfConfig.type === 'precampaign'" class="subheading" style="margin-top: 25px; margin-bottom: 5px;">
							<span style="margin-right: 20px;">Spot Schedule</span>
							<button v-if="['post_times','goldstdcsv'].includes(store.rfConfig.type)" @click="sortScheduleByDate">Sort By Date</button>
						</p>
						<div v-if="store.rfConfig.type !== 'dayparts_standard'" style="margin-bottom: 10px;">
							<div class="primary-button" @click="exportRfInCsv">
								<i class="mdi mdi-file-excel"></i>
								Export RFIN CSV (GFK Only)
							</div>
							<div class="primary-button" @click="exportAudologySchedule" style="margin-left: 10px;">
								<i class="mdi mdi-file-excel"></i>
								Export Audology Schedule CSV
							</div>
						</div>
						<RFSpotScheduleTablePre v-if="store.rfConfig.type === 'precampaign'" :spotplays="store.rfConfig.schedule"></RFSpotScheduleTablePre>

<!--						<RFDaypartsStandardScheduleEditor v-if="store.rfConfig.type === 'dayparts_standard'"></RFDaypartsStandardScheduleEditor>-->
						<RFQtrHrScheduleEditor v-if="store.rfConfig.type === 'qtrhr'"></RFQtrHrScheduleEditor>
<!--						<RFDaypartsFullScheduleEditor v-if="store.rfConfig.type === 'dayparts_full'"></RFDaypartsFullScheduleEditor>-->
						<RFPostTimesScheduleEditor v-if="store.rfConfig.type === 'post_times'"></RFPostTimesScheduleEditor>
						<RFGoldStdCSVScheduleEditor v-if="store.rfConfig.type === 'goldstdcsv'"></RFGoldStdCSVScheduleEditor>
					</div>

					<div v-if="!['precampaign'].includes(store.rfConfig.type)" style="margin: 15px 0; display: flex; gap: 10px;">
						<div class="primary-button" @click="addBlankRowToSchedule">
							<i class="mdi mdi-plus"></i>
							Add Spot Row
						</div>
						<div class="primary-button remove-button" @click="clearSchedule">
							<i class="mdi mdi-close"></i>
							Clear
						</div>
					</div>

					<div v-if="store.rfConfig.type !== 'dayparts_standard'">
						<div class="primary-button" @click="exportRfInCsv">
							<i class="mdi mdi-file-excel"></i>
							Export RFIN CSV (GFK Only)
						</div>
						<div class="primary-button" @click="exportAudologySchedule" style="margin-left: 10px;">
							<i class="mdi mdi-file-excel"></i>
							Export Audology Schedule CSV
						</div>
					</div>

				</div>
			</div>
		</div>
	</div>
</template>

<script>
import {store} from '@/store.js'
import {globalMixin} from "@/mixins"
// import ScheduleUploader from "@/components/rf/ScheduleUploader";
// import RFDaypartsStandardScheduleEditor from "@/components/rf/RFDaypartsStandardScheduleEditor";
// import RFDaypartsFullScheduleEditor from "@/components/rf/RFDaypartsFullScheduleEditor";
import RFQtrHrScheduleEditor from "@/components/rf/RFQtrHrScheduleEditor";
import RFPostTimesScheduleEditor from "@/components/rf/RFPostTimesScheduleEditor";
import TopTabBar from "@/components/TopTabBar.vue";
import ScheduleGSCSVUploader from "@/components/rf/ScheduleGSCSVUploader.vue";
import RFGoldStdCSVScheduleEditor from "@/components/rf/RFGoldStdCSVScheduleEditor.vue";
import RFPreCampaignEditor from "@/components/rf/RFPreCampaignEditor.vue";
import RFSpotScheduleTablePre from "@/components/result-tables/RFSpotScheduleTablePre.vue";
import SchedulePostTimesUploader from "@/components/rf/SchedulePostTimesUploader.vue";
export default {
	name: "ScheduleView",
	components: {
		SchedulePostTimesUploader,
		RFSpotScheduleTablePre,
		RFPreCampaignEditor,
		RFGoldStdCSVScheduleEditor,
		ScheduleGSCSVUploader,
		TopTabBar,
		RFPostTimesScheduleEditor,
		RFQtrHrScheduleEditor,
		// RFDaypartsFullScheduleEditor,
		// RFDaypartsStandardScheduleEditor,
		// ScheduleUploader,
	},
	mixins: [globalMixin],
	props: {},
	data: function () {
		return {
			store: store,
			spotGenerateWeekStartsOn: 'sun',
			spotGenerateFirstWeekDate: null, //by default today, otherwise can set date of first week start
		}
	},
	computed: {
		reportType() {
			return this.store.reportType;
		},
	},
	methods: {
		clearSchedule() {
			let c = confirm('Are you sure you want to clear the schedule?')
			if(c) this.store.rfConfig.schedule = []
		},
		exportRfInCsv() {
			this.sortScheduleByDate() //must be sorted before being exported
			let str = "FileType,Version,ScheduleCreateDate,SoftwareTag,Survey,MarketId,MarketDesc,SpotNumber,SpotDate,"
			str += "SpotTime,SpotDuration,StationId,StationShortDesc,AudioTypeId,Cost,SpotType,ScheduleDesc\n"
			let spotNumber = 0
			let createDate = this.getTodayRFCSVDate()
			for(let s of this.store.rfConfig.schedule) {
				if(s.stationId.indexOf('g_') > -1) {
					let survid = ''
					if(s.surveyCode && s.surveyCode.length > 0) {
						survid = s.surveyCode
					}
					else {
						survid = this.getRFSurveyCodeForSpotRow(s)
					}
					let survob = this.store.surveys.find(item => item.id === survid)
					let stnob = this.store.stations.find(item => item.id === s.stationId)
					let audmktid = stnob.mktid
					let survFieldWorkStart = null
					if(survob) {
						survFieldWorkStart = survob.fieldwork_start //YYYY-MM-DD - always a Sunday
					}
					let mktob = this.store.markets.find(item => item.id === audmktid)
					let playDate = '';
					if(s.playDate) playDate = s.playDate
					else if(s.dayOfWeek && s.weekNumber && survFieldWorkStart) { //generate date from week number and day of week
						playDate = this.getSpotDateFromSchedule(s.dayOfWeek, s.weekNumber, survFieldWorkStart)
					}
					let duration = 0
					if(s.spotDuration) duration = s.spotDuration
					let cost = 0
					if(s.spotRate) cost = s.spotRate
					let spotClass = ''
					if(s.spotClass) spotClass = s.spotClass
					spotNumber++
					let stationId = stnob.id.replace("g_", "")
					str += "RFIN,100,"+createDate+",Audology,"+survid+","+mktob.gfkMktid+","+mktob.gfk3shortcode+","+spotNumber+","+playDate+","
					if(s.playTime) str += s.playTime.slice(0, -3) //remove ":00" from end of time
					let spotdesc = '';
					if(s.campaign) spotdesc = s.campaign
					str += ","+duration+","+stationId+","+stnob.name+","+s.audioType+","+cost+","+spotClass+","+spotdesc+",\n"
				}
			}
			let csvContent = "data:text/csv;charset=utf-8," + str
			let encodedUri = encodeURI(csvContent)
			let link = document.createElement("a")
			link.setAttribute("href", encodedUri)
			link.setAttribute("download", "RFIN.csv")
			document.body.appendChild(link) // Required for FF
			link.click()
		},
		exportAudologySchedule() {
			this.sortScheduleByDate() //must be sorted before being exported
			let str = "FileType,Version,ScheduleCreateDate,SoftwareTag,Survey,MarketId,MarketDesc,SpotNumber,SpotDate,"
			str += "SpotTime,SpotDuration,StationId,StationShortDesc,AudioTypeId,Cost,SpotType,ScheduleDesc,"
			str += "Advertiser,ContractId,BookedDaypart,StartTime,EndTime,BookedDate,KeyNumber,PIBBooked,PIBAired,"
			str += "RatecardRate,AiredDaypart,DaypartRate\n"
			let spotNumber = 0
			let createDate = this.getTodayRFCSVDate()
			for(let s of this.store.rfConfig.schedule) {
				if(s.stationId.indexOf('g_') > -1 || s.stationId.indexOf('x_') > -1) {
					let survid = ''
					if(s.surveyCode && s.surveyCode.length > 0) survid = s.surveyCode
					else survid = this.getRFSurveyCodeForSpotRow(s)
					let survob = this.store.surveys.find(item => item.id === survid)
					let stnob = this.store.stations.find(item => item.id === s.stationId)
					let audmktid = stnob.mktid
					let survFieldWorkStart = null
					if(survob) {
						survFieldWorkStart = survob.fieldwork_start //YYYY-MM-DD - always a Sunday
					}
					let mktob = this.store.markets.find(item => item.id === audmktid)
					let playDate = '';
					if(s.playDate) playDate = s.playDate
					else if(s.dayOfWeek && s.weekNumber && survFieldWorkStart) { //generate date from week number and day of week
						playDate = this.getSpotDateFromSchedule(s.dayOfWeek, s.weekNumber, survFieldWorkStart)
					}
					let duration = 0
					if(s.spotDuration) duration = s.spotDuration
					let cost = 0
					if(s.spotRate) cost = s.spotRate
					let spotClass = ''
					if(s.spotClass) spotClass = s.spotClass
					spotNumber++
					str += "AudologySchedule,100,"+createDate+",Audology,"+survid+","+mktob.id+","+mktob.name+","+spotNumber+","+playDate+","
					if(s.playTime) str += s.playTime.slice(0, -3) //remove ":00" from end of time
					let spotdesc = '';
					if(s.product) spotdesc = s.product
					str += ","+duration+","+stnob.id+","+stnob.name+","+s.audioType+","+cost+","+spotClass+","+spotdesc+","
					let adv = ''
					if(s.campaign) adv = s.campaign
					let contrid = ''
					if(s.contractId) contrid = s.contractId
					let bookeddp = ''
					if(s.bookedDaypart) bookeddp = s.bookedDaypart
					let bookedStart = ''
					if(s.startTime) bookedStart = s.startTime
					let bookedEnd = ''
					if(s.endTime) bookedEnd = s.endTime
					let bookeddate = ''
					if(s.bookedDate) bookeddate = s.bookedDate
					let keyn = ''
					if(s.keyNumber) keyn = s.keyNumber
					let pibbooked = ''
					if(s.PIBBooked) pibbooked = s.PIBBooked
					let pibaired = ''
					if(s.PIBAired) pibaired = s.PIBAired
					let ratecardrate = ''
					if(s.ratecardRate) ratecardrate = s.ratecardRate
					let aireddp = ''
					if(s.airedDaypart) aireddp = s.airedDaypart
					let daypartrate = ''
					if(s.daypartRate) daypartrate = s.daypartRate
					str += adv+","+contrid+","+bookeddp+","+bookedStart+","+bookedEnd+","+bookeddate+","+keyn+","
					str += pibbooked+","+pibaired+","+ratecardrate+","+aireddp+","+daypartrate+",\n"
				}
			}
			let csvContent = "data:text/csv;charset=utf-8," + str
			let encodedUri = encodeURI(csvContent)
			let link = document.createElement("a")
			link.setAttribute("href", encodedUri)
			link.setAttribute("download", "AudologySchedule.csv")
			document.body.appendChild(link) // Required for FF
			link.click()
		},
	},
	watch: {

	},
	mounted() {
		if(!this.spotGenerateFirstWeekDate) {
			let today = new Date()
			let dd = String(today.getDate()).padStart(2, '0')
			let mm = String(today.getMonth() + 1).padStart(2, '0') //January is 0!
			let yyyy = today.getFullYear()
			this.spotGenerateFirstWeekDate = yyyy + '-' + mm + '-' + dd
		}
	}
}
</script>

<style scoped>
.view-holder {
	margin: 0 20px;
}
.input-dater {
	margin: 0 5px;
	font-weight: normal;
	font-size: 12px;
	font-family: 'Roboto', sans-serif;
	padding: 5px;
	border-radius: 5px;
	border: 1px solid var(--greymid);
}
</style>
<template>
	<div class="body-hold tabsNotShown">
		<div class="view-holder">

<!--			<p v-if="store.user.id === 1" style="margin-bottom: 10px;">Welcome, {{store.user.email}}</p>-->

			<NotificationBox>
				<p class="bold" style="font-size: 16px;">Welcome to Audology, {{store.user.email}}</p>
				<br/>
				<p>Please choose a report type to run below, or choose to open a saved job from the Jobs menu above.</p>
			</NotificationBox>

	<!--		<NotificationBox>-->
	<!--			<p class="bold" style="font-size: 16px;">Welcome to the latest version of Audology</p>-->
	<!--			<br/>-->
	<!--			<p>Please choose a report type to run below, or choose to open a saved job from the menu above as usual.</p>-->
	<!--		</NotificationBox>-->

			<div style="display: flex; flex-wrap: wrap; gap: 50px; padding: 20px 0;">
				<div class="large-tile-button" @click="setReportType('normal')">
					<div class="tile-inside">
						<div class="tile-icon-hold">
							<i class="mdi mdi-chart-tree"></i>
						</div>
						<p>Standard Report</p>
					</div>
				</div>
				<div class="large-tile-button" @click="triggerRFPrompt">
					<div class="tile-inside">
						<div class="tile-icon-hold">
							<i class="mdi mdi-radio-tower"></i>
						</div>
						<p>R&F Report</p>
					</div>
				</div>

				<div class="large-tile-button" @click="store.chartExplorerOverlayShown = true">
					<div class="tile-inside">
						<div class="tile-icon-hold">
							<i class="mdi mdi-chart-bar"></i>
						</div>
						<p>Chart Explorer</p>
					</div>
				</div>

				<div class="large-tile-button" @click="setReportType('multiRank')">
					<div class="tile-inside">
						<div class="tile-icon-hold">
							<i class="mdi mdi-numeric"></i>
						</div>
						<p>Multi-Station Combo Ranker</p>
					</div>
				</div>
			</div>

			<p class="heading">GFK Markets Special Reports</p>
			<div style="display: flex; flex-wrap: wrap; gap: 50px; padding: 0 0 20px;">
				<div class="large-tile-button" @click="setReportType('loyalty')">
					<div class="tile-inside">
						<div class="tile-icon-hold">
							<i class="mdi mdi-share-variant-outline"></i>
						</div>
						<p>Station Loyalty</p>
					</div>
				</div>
				<div class="large-tile-button" @click="setReportType('ebbAndFlow')">
					<div class="tile-inside">
						<div class="tile-icon-hold">
							<i class="mdi mdi-clock-outline"></i>
						</div>
						<p>Duplication / Ebb And Flow</p>
					</div>
				</div>
				<div class="large-tile-button" @click="setReportType('stationP1')">
					<div class="tile-inside">
						<div class="tile-icon-hold">
							<i class="mdi mdi-account-star"></i>
						</div>
						<p>Station P1 Report</p>
					</div>
				</div>
			</div>

			<p v-if="store.user.agencyUser === 0" class="heading">Xtra Markets Special Reports</p>
			<div style="display: flex; flex-wrap: wrap; gap: 50px; padding: 0 0 20px;">
				<div v-if="store.user.agencyUser === 0" class="large-tile-button" @click="setReportType('tslCrosstabs')">
					<div class="tile-inside">
						<div class="tile-icon-hold">
							<i class="mdi mdi-clock-outline"></i>
						</div>
						<p>TSL Crosstabs</p>
					</div>
				</div>
				<div v-if="store.user.agencyUser === 0" class="large-tile-button" @click="setReportType('pctStnCumeByDaypart')">
					<div class="tile-inside">
						<div class="tile-icon-hold">
							<i class="mdi mdi-stove"></i>
						</div>
						<p>Pct Cume By Daypart</p>
					</div>
				</div>
				<div v-if="store.user.agencyUser === 0" class="large-tile-button" @click="setReportType('audSharingCumeReg')">
					<div class="tile-inside">
						<div class="tile-icon-hold">
							<i class="mdi mdi-share-variant-outline"></i>
						</div>
						<p>Audience Sharing by Station Cume</p>
					</div>
				</div>
				<div v-if="store.user.agencyUser === 0" class="large-tile-button" @click="setReportType('audSharingMostReg')">
					<div class="tile-inside">
						<div class="tile-icon-hold">
							<i class="mdi mdi-star-outline"></i>
						</div>
						<p>Station Most (Overall/ROS) split by Station Cume</p>
					</div>
				</div>
				<div v-if="store.user.agencyUser === 0" class="large-tile-button" @click="setReportType('regFlowFromBreakfast')">
					<div class="tile-inside">
						<div class="tile-icon-hold">
							<i class="mdi mdi-coffee-outline"></i>
						</div>
						<p>Flow From Breakfast</p>
					</div>
				</div>
			</div>

		</div>
	</div>
</template>

<script>
import NotificationBox from "@/components/NotificationBox";
import {store} from '@/store.js'
import {globalMixin} from "@/mixins"
export default {
	name: "ReportTypeChooser",
	components: {
		NotificationBox
	},
	mixins: [globalMixin],
	props: {},
	data: function () {
		return {
			store: store,
		}
	},
	computed: {},
	methods: {
		triggerRFPrompt() {
			this.store.rfTypePromptOverlayShown = true
		},
	},
	watch: {},
	mounted() {

	}
}
</script>

<style scoped>
.view-holder {
	margin: 10px 20px 20px;
}
</style>
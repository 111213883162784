<template>
	<div>
		<TopTabBar>
			<template v-slot:belowtabs>
				<div class="flex" style="height: 100%;">
					<span class="freq-shortcut" @click="shortcutStations('clear')">Clear</span>
					<span class="freq-shortcut" @click="shortcutStations('active')">All Active</span>
					<span class="freq-shortcut" @click="shortcutStations('amfm')">Active AMFM</span>
					<span class="freq-shortcut" @click="shortcutStations('all')">All</span>
					<span v-if="store.user.isAdmin === 1" class="freq-shortcut" @click="shortcutStations('public')">Public</span>
				</div>
			</template>
		</TopTabBar>
		<div class="body-hold">
			<div class="view-holder nostyle">
				<div v-if="store.selectionObs.markets.length === 0">
					<p>Please choose at least one market first</p>
				</div>

				<ListsChooser v-else v-on:filterupdate="filterText = $event">
					<template v-slot:interior_tabs>
						<InteriorTab id="stations" name="Stations" :isactive="viewStationsOrCombos === 'stations'" @set="viewStationsOrCombos = $event"></InteriorTab>
						<InteriorTab v-if="allowComboTabs" id="combos" name="Combos (Global)" :isactive="viewStationsOrCombos === 'combos'" @set="viewStationsOrCombos = $event"></InteriorTab>
						<InteriorTab v-if="allowComboTabs" id="combosSite" name="Site" :isactive="viewStationsOrCombos === 'combosSite'" @set="viewStationsOrCombos = $event"></InteriorTab>
						<InteriorTab v-if="allowComboTabs" id="combosUser" name="User" :isactive="viewStationsOrCombos === 'combosUser'" @set="viewStationsOrCombos = $event"></InteriorTab>
					</template>
					<template v-slot:left_items>
						<div v-if="viewStationsOrCombos === 'stations'" style="padding-bottom: 20px;">
							<div style="display: flex; gap: 10px;">
								<div>
									<input id="showdabs" type="checkbox" v-model="store.showDabStations">
									<label for="showdabs">Show DAB+ Stations</label>
								</div>
								<div v-if="store.user.isAdmin === 1 || store.user.viewCommunities === 1">
									<input id="showprivs" type="checkbox" v-model="store.showPrivateStations">
									<label for="showprivs">Show Private Stations</label>
								</div>
							</div>
							<p class="boldme stngrouphead">Stations</p>
							<StationMarketGroup v-for="m in store.selectionObs.markets" :key="m.id" :mktid="m.id" :filtertext="filterText"
								:hiddenmarketids="hiddenMarketIds" v-on:toggleviewed="toggleviewed($event)"
							></StationMarketGroup>
						</div>
						<div v-if="allowComboTabs && viewStationsOrCombos === 'combos'" style="padding-bottom: 20px;">
							<div>
								<p class="boldme stngrouphead" style="margin-top: 0;">Network Groups</p>
								<p style="font-size: 12px; font-style: italic; margin-bottom: 10px;">Stations as assigned to network at time of survey.</p>
								<div v-for="stn in filteredSupplierCombosNetGroups" :key="stn.id" class="checklist-item">
									<div class="ch-and-title" @click="toggleStationInSelection(stn)">
										<span class="toggler"><i v-if="store.selectionObs.stations.some(item => item.id === stn.id)" class="mdi mdi-check-bold"></i></span>
										<span class="label" :id="'stnpop'+stn.id">{{stn.name}}</span>
									</div>
									<InfoIcon v-on:clicked="showComboPopoverDo(stn)" addclasses="small" title="Click to view combo information"></InfoIcon>
								</div>
							</div>
							<div>
								<p class="boldme stngrouphead">Sub Networks</p>
								<p style="font-size: 12px; font-style: italic; margin-bottom: 10px;">Stations as assigned to network at time of survey.</p>
								<div v-for="stn in filteredSupplierCombosSubNets" :key="stn.id" class="checklist-item">
									<div class="ch-and-title" @click="toggleStationInSelection(stn)">
										<span class="toggler"><i v-if="store.selectionObs.stations.some(item => item.id === stn.id)" class="mdi mdi-check-bold"></i></span>
										<span class="label" :id="'stnpop'+stn.id">{{stn.name}}</span>
									</div>
									<InfoIcon v-on:clicked="showComboPopoverDo(stn)" addclasses="small" title="Click to view combo information"></InfoIcon>
								</div>
							</div>
							<div>
								<p class="boldme stngrouphead">Representation Group</p>
								<p style="font-size: 12px; font-style: italic; margin-bottom: 10px;">Stations as assigned to network at time of survey.</p>
								<div v-for="stn in filteredSupplierCombosReps" :key="stn.id" class="checklist-item">
									<div class="ch-and-title" @click="toggleStationInSelection(stn)">
										<span class="toggler"><i v-if="store.selectionObs.stations.some(item => item.id === stn.id)" class="mdi mdi-check-bold"></i></span>
										<span class="label" :id="'stnpop'+stn.id">{{stn.name}}</span>
									</div>
									<InfoIcon v-on:clicked="showComboPopoverDo(stn)" addclasses="small" title="Click to view combo information"></InfoIcon>
								</div>
							</div>
							<div>
								<p class="boldme stngrouphead">Representation Sub Network</p>
								<p style="font-size: 12px; font-style: italic; margin-bottom: 10px;">Stations as assigned to network at time of survey.</p>
								<div v-for="stn in filteredSupplierCombosRepSubNets" :key="stn.id" class="checklist-item">
									<div class="ch-and-title" @click="toggleStationInSelection(stn)">
										<span class="toggler"><i v-if="store.selectionObs.stations.some(item => item.id === stn.id)" class="mdi mdi-check-bold"></i></span>
										<span class="label" :id="'stnpop'+stn.id">{{stn.name}}</span>
									</div>
									<InfoIcon v-on:clicked="showComboPopoverDo(stn)" addclasses="small" title="Click to view combo information"></InfoIcon>
								</div>
							</div>
							<div>
								<p class="boldme stngrouphead">Network Planning Combos</p>
								<div v-for="stn in filteredSupplierCombosCustom" :key="stn.id" class="checklist-item">
									<div class="ch-and-title" @click="toggleStationInSelection(stn)">
										<span class="toggler"><i v-if="store.selectionObs.stations.some(item => item.id === stn.id)" class="mdi mdi-check-bold"></i></span>
										<span class="label" :id="'stnpop'+stn.id">{{stn.name}}</span>
									</div>
									<InfoIcon v-on:clicked="showComboPopoverDo(stn)" addclasses="small" title="Click to view combo information"></InfoIcon>
								</div>
							</div>
							<div>
								<p class="boldme stngrouphead">DAB Only National Brands</p>
								<div v-for="stn in filteredSupplierCombosDABOnly" :key="stn.id" class="checklist-item">
									<div class="ch-and-title" @click="toggleStationInSelection(stn)">
										<span class="toggler"><i v-if="store.selectionObs.stations.some(item => item.id === stn.id)" class="mdi mdi-check-bold"></i></span>
										<span class="label" :id="'stnpop'+stn.id">{{stn.name}}</span>
									</div>
									<InfoIcon v-on:clicked="showComboPopoverDo(stn)" addclasses="small" title="Click to view combo information"></InfoIcon>
								</div>
							</div>
							<div v-if="store.reportType !== 'reachFrequency'">
								<p class="boldme stngrouphead">Supplied Station Combos</p>
								<p style="font-size: 12px; font-style: italic; margin-bottom: 10px;">The following are commonly used station combos, often used for historical purposes when stations have evolved or changed over time.
									<br/>Only showing station combos relevant for the markets selected.
								</p>
								<div v-for="stn in filteredSupplierCombosStations" :key="stn.id" class="checklist-item">
									<div class="ch-and-title" @click="toggleStationInSelection(stn)">
										<span class="toggler"><i v-if="store.selectionObs.stations.some(item => item.id === stn.id)" class="mdi mdi-check-bold"></i></span>
										<span class="label" :id="'stnpop'+stn.id">{{stn.name}}</span>
									</div>
									<InfoIcon v-on:clicked="showComboPopoverDo(stn)" addclasses="small" title="Click to view combo information"></InfoIcon>
								</div>
							</div>
							<div v-if="store.reportType !== 'reachFrequency'">
								<p class="boldme stngrouphead">Now Snapshot Combos</p>
								<p style="font-size: 12px; font-style: italic; margin-bottom: 10px;">
									DANGER: Stations in combos below are as assigned TODAY, not as they were at time of survey.
									These are provided to assist in like-for-like trending comparisons but may not reflect the ratings at the time.
									For example 96FM Perth was acquired by ARN in 2015.  The main combos above only include 96FM in ARN combos from 2015 onwards, however the ARN combo below includes 96FM for all Perth surveys.
									Only use the combos below if you want to see station network assignments as they exist today.
								</p>
								<div v-for="stn in filteredSupplierCombosNowNoSxS" :key="stn.id" class="checklist-item">
									<div class="ch-and-title" @click="toggleStationInSelection(stn)">
										<span class="toggler"><i v-if="store.selectionObs.stations.some(item => item.id === stn.id)" class="mdi mdi-check-bold"></i></span>
										<span class="label" :id="'stnpop'+stn.id">{{stn.name}}</span>
									</div>
									<InfoIcon v-on:clicked="showComboPopoverDo(stn)" addclasses="small" title="Click to view combo information"></InfoIcon>
								</div>
							</div>
						</div>
						<div v-if="allowComboTabs && viewStationsOrCombos === 'combosSite'" style="padding-bottom: 20px; margin-top: -20px;">
							<div>
								<div v-if="store.sites.length > 0">
									<div v-for="sa in store.sites" :key="sa.id" class="">
										<p class="boldme stngrouphead">{{sa.name}}</p>
										<ComboViewGroup :siteaccessid="sa.id" :combos="getCombosFromSiteId(sa.id)" v-on:togglecombo="toggleStationInSelection($event)" v-on:showpopover="showComboPopoverDo($event)"></ComboViewGroup>
									</div>
								</div>
							</div>
						</div>
						<div v-if="allowComboTabs && viewStationsOrCombos === 'combosUser'" style="padding-bottom: 20px;">
							<div>
								<div style="margin-bottom: 10px;">
									<span class="primary-button" @click="createNewCombo" title="New Station Combo">
										<i class="mdi mdi-plus"></i> Create New
									</span>
									<span class="primary-button" @click="store.combosManageOverlayShown = true" style="margin-left:10px;">
										<i class="mdi mdi-cog"></i> Manage
									</span>
								</div>
								<ComboViewGroup :combos="filteredUserCombos" v-on:togglecombo="toggleStationInSelection($event)" v-on:showpopover="showComboPopoverDo($event)"></ComboViewGroup>
							</div>
						</div>
					</template>

					<template v-slot:rightside>
						<div class="addNewFolderHold">
							<span class="primary-button" @click="createNewCombo" style="flex-shrink: 0;">
								<i class="mdi mdi-folder" style="margin-right: 5px;"></i>
								<span>New Combo</span>
							</span>
							<span style="font-size: 11px;">

							</span>
						</div>
						<draggable v-model="mylist" item-key="index" v-bind="dragOptions">
							<template #item="{index}">
								<SortableInternal type="station" :objid="mylist[index].id" v-on:remove="toggleStationInSelection(mylist[index])"></SortableInternal>
							</template>
						</draggable>
					</template>
				</ListsChooser>

				<div v-if="showComboPopover" class="loader-overlay">
					<div class="market-summary-view">
						<StationComboPopover :stnob="stnComboOb" v-on:close="showComboPopover = false"></StationComboPopover>
					</div>
				</div>

			</div>
		</div>

	</div>
</template>

<script>
import StationMarketGroup from "@/components/station-selections/StationMarketGroup";
import SortableInternal from "@/components/SortableInternal";
import StationComboPopover from "@/components/station-selections/StationComboPopover";
import InfoIcon from "@/components/InfoIcon";
import ComboViewGroup from "@/components/combos/ComboViewGroup";
import {store} from '@/store.js'
import {globalMixin} from "@/mixins"
import draggable from 'vuedraggable'
import TopTabBar from "@/components/TopTabBar.vue";
import ListsChooser from "@/components/views/ListsChooser.vue";
import InteriorTab from "@/components/InteriorTab.vue";

export default {
	name: "StationsView",
	components: {
		InteriorTab,
		ListsChooser,
		TopTabBar,
		ComboViewGroup,
		InfoIcon,
		StationComboPopover,
		SortableInternal,
		StationMarketGroup,
		draggable
	},
	mixins: [globalMixin],
	props: {},
	data: function () {
		return {
			store: store,
			filterText: '',
			showComboPopover: false,
			stnComboOb: null,
			hiddenMarketIds: [],
			viewStationsOrCombos: 'stations', //stations, combos, combosUser
		}
	},
	computed: {
		allowComboTabs() {
			if(this.reportType !== 'reachFrequency') return true
			else if(this.reportType === 'reachFrequency' && this.store.rfConfig.type === 'precampaign') return true
			else return false //R&F any type of Config Type that is not 'precampaign'
		},
		mylist: {
			get() {
				return this.store.selectionObs.stations;
			},
			set(value) {
				this.store.selectionObs.stations = value;
			}
		},
		dragOptions() {
			return {
				animation: 200,
				disabled: false,
				ghostClass: "ghost-drag"
			}
		},
		reportType() {
			return this.store.reportType;
		},
		filteredSupplierCombos() {
			let mycombos = this.store.stationcombos.filter(item => (item.mktid === 0 || this.store.selectionObs.markets.some(elem => elem.id === item.mktid)) && item.isSystem === true);
			if(this.filterText.length === 0) return mycombos;
			else { //either already selected or matching text
				return mycombos.filter(item => this.store.selectionObs.stations.some(elem => elem.id === item.id) || item.name.toLowerCase().includes(this.filterText.toLowerCase()));
			}
		},
		filteredSupplierCombosNetGroups() {
			let mycombos = this.store.stationcombos.filter(item => (item.mktid === 0 || this.store.selectionObs.markets.some(elem => elem.id === item.mktid)) && item.isSystem === true && item.networkLevel === 1 && item.isSnapshotCombo === true);
			if(this.filterText.length === 0) return mycombos;
			else { //either already selected or matching text
				return mycombos.filter(item => this.store.selectionObs.stations.some(elem => elem.id === item.id) || item.name.toLowerCase().includes(this.filterText.toLowerCase()));
			}
		},
		filteredSupplierCombosSubNets() {
			let mycombos = this.store.stationcombos.filter(item => (item.mktid === 0 || this.store.selectionObs.markets.some(elem => elem.id === item.mktid)) && item.isSystem === true && item.networkLevel === 2 && item.isSnapshotCombo === true);
			if(this.filterText.length === 0) return mycombos;
			else { //either already selected or matching text
				return mycombos.filter(item => this.store.selectionObs.stations.some(elem => elem.id === item.id) || item.name.toLowerCase().includes(this.filterText.toLowerCase()));
			}
		},
		filteredSupplierCombosReps() {
			let mycombos = this.store.stationcombos.filter(item => (item.mktid === 0 || this.store.selectionObs.markets.some(elem => elem.id === item.mktid)) && item.isSystem === true && item.networkLevel === 3 && item.isSnapshotCombo === true);
			if(this.filterText.length === 0) return mycombos;
			else { //either already selected or matching text
				return mycombos.filter(item => this.store.selectionObs.stations.some(elem => elem.id === item.id) || item.name.toLowerCase().includes(this.filterText.toLowerCase()));
			}
		},
		filteredSupplierCombosRepSubNets() {
			let mycombos = this.store.stationcombos.filter(item => (item.mktid === 0 || this.store.selectionObs.markets.some(elem => elem.id === item.mktid)) && item.isSystem === true && item.networkLevel === 4 && item.isSnapshotCombo === true);
			if(this.filterText.length === 0) return mycombos;
			else { //either already selected or matching text
				return mycombos.filter(item => this.store.selectionObs.stations.some(elem => elem.id === item.id) || item.name.toLowerCase().includes(this.filterText.toLowerCase()));
			}
		},
		filteredSupplierCombosDABOnly() {
			let mycombos = this.store.stationcombos.filter(item => (item.mktid === 0 || this.store.selectionObs.markets.some(elem => elem.id === item.mktid)) && item.isSystem === true && item.networkLevel === 5 && item.isSnapshotCombo === true);
			if(this.filterText.length === 0) return mycombos;
			else { //either already selected or matching text
				return mycombos.filter(item => this.store.selectionObs.stations.some(elem => elem.id === item.id) || item.name.toLowerCase().includes(this.filterText.toLowerCase()));
			}
		},
		filteredSupplierCombosCustom() { //eg custom SCA ones like Hit Sydney Network (incl DAB)
			let mycombos = this.store.stationcombos.filter(item => (item.mktid === 0 || this.store.selectionObs.markets.some(elem => elem.id === item.mktid)) && item.isSystem === true && item.networkLevel === 6 && item.isSnapshotCombo === true);
			if(this.filterText.length === 0) return mycombos;
			else { //either already selected or matching text
				return mycombos.filter(item => this.store.selectionObs.stations.some(elem => elem.id === item.id) || item.name.toLowerCase().includes(this.filterText.toLowerCase()));
			}
		},
		filteredSupplierCombosStations() {
			let mycombos = this.store.stationcombos.filter(item => (item.mktid === 0 || this.store.selectionObs.markets.some(elem => elem.id === item.mktid)) && item.isSystem === true && item.networkLevel === 0);
			if(this.filterText.length === 0) return mycombos;
			else { //either already selected or matching text
				return mycombos.filter(item => this.store.selectionObs.stations.some(elem => elem.id === item.id) || item.name.toLowerCase().includes(this.filterText.toLowerCase()));
			}
		},
		filteredSupplierCombosNowNoSxS() {
			let mycombos = this.store.stationcombos.filter(item => (item.mktid === 0 || this.store.selectionObs.markets.some(elem => elem.id === item.mktid)) && item.isSystem === true && item.isSnapshotCombo === false && item.networkLevel > 0);
			if(this.filterText.length === 0) return mycombos;
			else { //either already selected or matching text
				return mycombos.filter(item => this.store.selectionObs.stations.some(elem => elem.id === item.id) || item.name.toLowerCase().includes(this.filterText.toLowerCase()));
			}
		},
		filteredUserCombos() {
			let mycombos = this.store.stationcombos.filter(item => (item.mktid === 0 || this.store.selectionObs.markets.some(elem => elem.id === item.mktid)) && item.isSystem === false && item.siteAccessId === 0 && item.userid !== 0)
			if(this.filterText.length === 0) return mycombos;
			else { //either already selected or matching text
				return mycombos.filter(item => this.store.selectionObs.stations.some(elem => elem.id === item.id) || item.name.toLowerCase().includes(this.filterText.toLowerCase()));
			}
		},
	},
	methods: {
		getCombosFromSiteId(sid) {
			let mycombos = this.store.stationcombos.filter(item => (item.mktid === 0 || this.store.selectionObs.markets.some(elem => elem.id === item.mktid)) && item.isSystem === false && item.siteAccessId === sid);
			if(this.filterText.length === 0) return mycombos;
			else { //either already selected or matching text
				return mycombos.filter(item => this.store.selectionObs.stations.some(elem => elem.id === item.id) || item.name.toLowerCase().includes(this.filterText.toLowerCase()));
			}
		},
		createNewCombo() {
			this.store.addToStationListAfterComboEdit = true
			this.store.comboEditOverlayShown = true
		},
		showComboPopoverDo(stncomboOb) {
			this.stnComboOb = stncomboOb;
			this.showComboPopover = true;
		},
		toggleviewed(mktid) {
			if(this.hiddenMarketIds.includes(mktid)) {
				let x = this.hiddenMarketIds.filter(item => item !== mktid);
				this.hiddenMarketIds = x;
			}
			else {
				this.hiddenMarketIds.push(mktid);
			}
		},
		shortcutStations(val) {
			let sels = [];
			if(val === 'clear') {
				sels = [];
			}
			else {
				for(let mymkt of this.store.selectionObs.markets) {
					if(mymkt) {
						let mystnids = mymkt.allStidsInOrder
						if(val === 'public') {
							mystnids = mymkt.publicStidsInOrder
						}
						for(let i=0; i<mystnids.length; i++) {
							let mystnob = this.store.stations.find(item => item.id === mystnids[i]);
							if(mystnob) {
								if(mystnob.community === false || (mystnob.community === true && this.store.user.viewCommunities === 1)) {
									if(val === 'all') sels.push(mystnob);
									else if(val === 'active' && mystnob.isActive === true) sels.push(mystnob);
									else if(val === 'amfm' && (['am','fm'].includes(mystnob.band) || mystnob.netgroupown === 'abc' || mystnob.isOther === true) && mystnob.band !== 'dab' && mystnob.community === false && mystnob.isActive === true) sels.push(mystnob);
								}
							}
						}
					}
				}
			}
			this.store.selectionObs.stations = sels;
		},
	},
	watch: {

	},
	mounted() {
		document.documentElement.style.scrollBehavior = 'auto';
		setTimeout(() => window.scrollTo(0, 0), 5); //tiny timeout to let page load first
	}
}
</script>

<style scoped>
.view-holder {
	margin: 0 20px 20px;
	padding: 0;
}
.market-summary-view {
	width: 600px;
	height: 80%;
	position: fixed;
	top: 5%;
	margin: 0 auto;
}
.stngrouphead {
	font-size: 16px;
	margin: 20px 0 10px;
}
.addNewFolderHold {
	padding: 5px;
	border-bottom: 1px solid var(--greymid);
	display: flex;
	gap: 20px;
	align-items: center;
}
</style>
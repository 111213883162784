<template>
	<div>
		<TopTabBar :hidebelowtabs="true">
			<template v-slot:belowtabs>

			</template>
		</TopTabBar>
		<div class="body-hold hide-below-tabs">
			<div v-if="reportType === 'ebbAndFlow'" style="margin-bottom: 10px;">
				<p class="red">For Ebb and Flow analysis, choose TWO dayparts (the first will be the FROM daypart, the second the TO daypart).
					<br/>If you wish to see audience sharing across stations for a single daypart just choose ONE daypart.</p>
			</div>
			<ListsChooser v-on:filterupdate="filterText = $event">
				<template v-slot:interior_tabs>
					<InteriorTab id="global" name="Global" :isactive="viewtype === 'global'" @set="viewtype = $event"></InteriorTab>
					<InteriorTab id="site" name="Site" :isactive="viewtype === 'site'" @set="viewtype = $event"></InteriorTab>
					<InteriorTab id="user" name="User" :isactive="viewtype === 'user'" @set="viewtype = $event"></InteriorTab>
				</template>
				<template v-slot:left_items>
					<div v-if="viewtype === 'global'">
						<p class="boldme grouphead" style="margin-top: 0;">GFK & Xtra</p>
						<div v-for="st in filteredGlobalDayparts" :key="st.id" class="checklist-item">
							<div class="daypartItem">
								<div class="ch-and-title" @click="toggleDaypartInSelection(st)">
									<span class="toggler">
										<i v-if="store.selectionObs.dayparts.some(item => item.id === st.id)" class="mdi mdi-check-bold"></i>
									</span>
									<span class="label" :title="'API ID: '+st.id">{{st.name}}</span>
								</div>
								<div class="dp-edit-hold" v-if="st.owner === store.user.id" @click="editDaypart(st.id)" title="Edit daypart">
									<i class="mdi mdi-pencil-outline"></i>
								</div>
							</div>
						</div>

						<p class="boldme grouphead">GFK Only</p>
						<div v-for="st in filteredSystemGFKDayparts" :key="st.id" class="checklist-item">
							<div class="daypartItem">
								<div class="ch-and-title" @click="toggleDaypartInSelection(st)">
									<span class="toggler">
										<i v-if="store.selectionObs.dayparts.some(item => item.id === st.id)" class="mdi mdi-check-bold"></i>
									</span>
									<span class="label" :title="'API ID: '+st.id">{{st.name}}</span>
								</div>
							</div>
						</div>
					</div>
					<div v-else-if="viewtype === 'site'" style="margin-top: -20px;">
						<div v-if="store.sites.length > 0">
							<div v-for="sa in store.sites" :key="sa.id" class="">
								<p class="boldme grouphead">{{sa.name}}</p>
								<DaypartViewGroup :dayparts="getDaypartsFromSiteId(sa.id)" v-on:toggledaypart="toggleDaypartInSelection($event)"></DaypartViewGroup>
							</div>
						</div>
					</div>
					<div v-else-if="viewtype === 'user'">
						<div style="margin-bottom: 10px;">
							<span class="primary-button" @click="editDaypart(0)" >
								<i class="mdi mdi-plus"></i> Create New
							</span>
							<span class="primary-button" @click="store.daypartManageOverlayShown = true" style="margin-left:10px;">
								<i class="mdi mdi-cog"></i> Manage
							</span>
						</div>
						<DaypartViewGroup :dayparts="filteredUserDayparts" v-on:toggledaypart="toggleDaypartInSelection($event)"></DaypartViewGroup>
					</div>
				</template>
				<template v-slot:rightside>
					<draggable v-model="mylist" item-key="index" v-bind="dragOptions">
						<template #item="{index}">
							<SortableInternal type="daypart" :objid="mylist[index].id" v-on:remove="toggleDaypartInSelection(mylist[index])"></SortableInternal>
						</template>
					</draggable>
				</template>
			</ListsChooser>
		</div>
	</div>
</template>

<script>
import SortableInternal from "@/components/SortableInternal";
import {store} from '@/store.js'
import {globalMixin} from "@/mixins"
import draggable from 'vuedraggable'
import TopTabBar from "@/components/TopTabBar.vue";
import ListsChooser from "@/components/views/ListsChooser.vue";
import InteriorTab from "@/components/InteriorTab.vue";
import DaypartViewGroup from "@/components/dayparts/DaypartViewGroup.vue";
export default {
	name: "DaypartsView",
	components: {
		DaypartViewGroup,
		InteriorTab,
		ListsChooser,
		TopTabBar,
		SortableInternal,
		draggable
	},
	mixins: [globalMixin],
	props: {},
	data: function () {
		return {
			store: store,
			filterText: '',

			viewtype: 'global',
		}
	},
	computed: {
		reportType() {
			return this.store.reportType;
		},
		mylist: {
			get() {
				return this.store.selectionObs.dayparts;
			},
			set(value) {
				this.store.selectionObs.dayparts = value;
			}
		},
		dragOptions() {
			return {
				animation: 200,
				disabled: false,
				ghostClass: "ghost-drag"
			}
		},
		filteredGlobalDayparts() {
			let mydayps = this.store.dayparts.filter(item => item.isSystem === true && item.xtra === true && item.siteAccessId === 0)
			if(this.filterText.length === 0) return mydayps
			else { //either already selected or matching text
				return mydayps.filter(item => this.store.selectionObs.dayparts.some(elem => elem.id === item.id) || item.name.toLowerCase().includes(this.filterText.toLowerCase()));
			}
		},
		filteredSystemGFKDayparts() {
			let mydayps = this.store.dayparts.filter(item => item.isSystem === true && item.xtra !== true && item.gfk === true && item.siteAccessId === 0)
			mydayps.sort((a,b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0))
			if(this.filterText.length === 0) return mydayps
			else { //either already selected or matching text
				return mydayps.filter(item => this.store.selectionObs.dayparts.some(elem => elem.id === item.id) || item.name.toLowerCase().includes(this.filterText.toLowerCase()));
			}
		},
		filteredUserDayparts() {
			let mydayps = this.store.dayparts.filter(item => item.isSystem !== true && item.siteAccessId === 0 && item.owner !== 0)
			mydayps.sort((a,b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0))
			if(this.filterText.length === 0) return mydayps
			else { //either already selected or matching text
				return mydayps.filter(item => this.store.selectionObs.dayparts.some(elem => elem.id === item.id) || item.name.toLowerCase().includes(this.filterText.toLowerCase()));
			}
		},
	},
	methods: {
		getDaypartsFromSiteId(sid) {
			let mydayps = this.store.dayparts.filter(item => item.siteAccessId === sid)
			if(this.filterText.length === 0) return mydayps
			else { //either already selected or matching text
				return mydayps.filter(item => this.store.selectionObs.dayparts.some(elem => elem.id === item.id) || item.name.toLowerCase().includes(this.filterText.toLowerCase()));
			}
		},
	},
	watch: {

	},
	mounted() {
		document.documentElement.style.scrollBehavior = 'auto';
		setTimeout(() => window.scrollTo(0, 0), 5); //tiny timeout to let page load first
	}
}
</script>

<style scoped>
.body-hold {
	margin: 20px;
	margin-top: 110px !important;
}
.grouphead {
	font-size: 16px;
	margin: 20px 0 10px;
}
.daypartItem {
	display: flex;
	align-items: center;
}
</style>
<template>
	<div class="loader-overlay">
		<div class="popup-container">
			<div class="popup-topbar">
				<span class="bold">Jobs List</span>
				<div class="filterbox-hold">
					<input type="text" placeholder="Filter jobs..." v-model="filterText" class="filterbox" />
				</div>
				<span class="closer" @click="store.jobManageOverlayShown = false">
					<i class="mdi mdi-close"></i>
				</span>
			</div>
			<div class="popup-insides">

				<div v-if="fetching">Loading...</div>

				<div v-else>
					<div class="inside-tabs">
						<div class="tab" :class="{active: activetab === 'myjobs'}" @click="activetab = 'myjobs'">My Jobs</div>
<!--						<div v-if="store.sitesAdmin.length > 0" class="tab" :class="{active: activetab === 'sitejobs'}" @click="activetab = 'sitejobs'">Site Jobs</div>-->
						<div class="tab" :class="{active: activetab === 'sharedjobs'}" @click="activetab = 'sharedjobs'">Shared With Me</div>
					</div>

					<div v-if="activetab === 'myjobs'">
						<JobManageGroup :jobs="filteredJobs"
							v-on:deletejob="delJob($event)"
							v-on:openjob="openJob($event)"
							v-on:changename="changeJobName($event)"
						></JobManageGroup>
					</div>
					<div v-if="activetab === 'sharedjobs'">
						<JobManageFolder
							:jobs="sharedJobs" sharetype="shared"
							v-on:openjob="openJob($event)"
						>
						</JobManageFolder>
					</div>

				</div>

			</div>
		</div>
	</div>
</template>

<script>
import axios from "axios";
import {store} from '@/store.js'
import {globalMixin} from "@/mixins"
import JobManageGroup from "@/components/jobs/JobManageGroup.vue";
import JobManageFolder from "@/components/jobs/JobManageFolder.vue";
export default {
	name: "JobsManage",
	components: {JobManageFolder, JobManageGroup},
	mixins: [globalMixin],
	props: {},
	data: function () {
		return {
			store: store,
			fetching: false,
			filterText: '',
			activetab: 'myjobs', //myjobs, sitejobs, sharedjobs
		}
	},
	computed: {
		filteredJobs() {
			if(this.filterText.length === 0) {
				return this.store.jobs
			}
			else { //either already selected or matching text
				return this.store.jobs.filter(item => item.name.toLowerCase().includes(this.filterText.toLowerCase()))
			}
		},
		sharedJobs() {
			return this.filteredJobs.filter(item => item.ownerId !== this.store.user.id && item.receiveShareType && item.receiveShareType === 'shared')
		},
	},
	methods: {
		fetchJobList() {
			if(this.fetching === false) {
				this.fetching = true;
				let self = this;
				axios.post(
					"post/jobs-get-list.php",
					JSON.stringify({
						usercreds: self.store.usercreds,
					})
				).then(function (response) {
					let ret = response.data //response.data is returned info
					if (ret.jobs) self.store.jobs = ret.jobs
					if(ret.userListById) {
						for(let uid in ret.userListById) {
							self.store.userListById[uid] = ret.userListById[uid]

						}
					}
					self.fetching = false;
				}).catch(function (error) {
					console.log(error);
				});
			}
		},
		openJob(jid) {
			if(this.fetching === false) {
				this.fetching = true;
				let self = this;
				axios.post(
					"post/job-get-content.php",
					JSON.stringify({
						usercreds: self.store.usercreds,
						jobid: jid,
					})
				).then(function (response) {
					let ret = response.data //response.data is returned info
					let extraDayparts = []
					let extraDemos = []
					let extraCombos = []
					if(ret.extraDayparts) extraDayparts = ret.extraDayparts
					if(ret.extraDemos) extraDemos = ret.extraDemos
					if(ret.extraCombos) extraCombos = ret.extraCombos
					if (ret.job) {
						self.openJobAfterFetch(ret.job, extraDayparts, extraDemos, extraCombos)
					}
					self.fetching = false;
				}).catch(function (error) {
					console.log(error);
				});
			}
		},
		openJobAfterFetch(myjob, extraDayparts, extraDemos, extraCombos) {
			//let myjob = this.store.jobs.find(item => item.id === jid); //old way when we got all json at once
			//handle the surveysets
			let surveySets = this.handleIncomingSurveysetsFromJob(myjob.surveySets)
			//add any new dayparts, demos and combos to store if they came in with the job
			if(extraDayparts.length > 0) {
				for(let dp of extraDayparts) {
					let dpob = this.store.dayparts.findIndex(item => item.id === dp.id)
					if(dpob === -1) this.store.dayparts.push(dp)
				}
			}
			if(extraDemos.length > 0) {
				for(let dm of extraDemos) {
					let dmob = this.store.demographics.findIndex(item => item.id === dm.id)
					if(dmob === -1) this.store.demographics.push(dm)
				}
			}
			if(extraCombos.length > 0) {
				for(let cb of extraCombos) {
					let cbob = this.store.stationcombos.findIndex(item => item.id === cb.id)
					if(cbob === -1) this.store.stationcombos.push(cb)
				}
			}
			//handle stations and combos
			let stationArr = []
			for(let id of myjob.stationidArr) {
				let newob = this.store.stations.find(item => item.id === id)
				if(newob) stationArr.push(newob)
				else { //check for combos
					newob = this.store.stationcombos.find(item => item.id === id)
					if(newob) stationArr.push(newob)
				}
			}
			this.store.selectionObs = {
				markets: this.getObjectsFromArrayById(this.store.markets, myjob.marketidArr),
				surveys: surveySets,
				stations: stationArr,
				demographics: this.getObjectsFromArrayById(this.store.demographics, myjob.demoidArr),
				dayparts: this.getObjectsFromArrayById(this.store.dayparts, myjob.daypartsidArr),
				statistics: this.getObjectsFromArrayById(this.store.statistics, myjob.statisticsidArr),
			};
			this.store.splitByQuarterHours = myjob.splitQtr //Job class has this false by default if not set
			if(myjob.splitQtr === true) this.store.selectionObs.qtrHrs = this.store.qtrHrs
			this.store.splitByPlaceOfListen = myjob.splitPOL //Job class has this false by default if not set
			if(myjob.splitPOL === true) this.store.selectionObs.places = this.store.places
			this.store.splitByAudioType = myjob.splitAudType //Job class has this false by default if not set
			if(myjob.splitAudType === true) this.store.selectionObs.audioTypes = this.store.audioTypes
			if(myjob.layout && myjob.layout.tables && myjob.layout.tables.length > 0) this.store.tableLayout = myjob.layout
			else this.store.tableLayout = this.getDefaultTableLayout()
			if(myjob.viewPreferences) {
				let myprefs = {}
				let vp = this.getDefaultViewPreferences()
				for(let prop in vp) {
					if(Object.prototype.hasOwnProperty.call(myjob.viewPreferences, prop)) {
						myprefs[prop] = myjob.viewPreferences[prop]
					}
					else {
						myprefs[prop] = vp[prop]
					}
				}
				this.store.viewPreferences = myprefs
			}
			else this.store.viewPreferences = this.getDefaultViewPreferences()
			this.store.jobname = myjob.name;
			this.store.jobid = myjob.id;
			this.store.reportType = myjob.reporttype;
			if(myjob.shareableLinkSlug) this.store.shareableLinkSlug = myjob.shareableLinkSlug
			else this.store.shareableLinkSlug = ''
			if(myjob.rfjson) {
				let myrfconfig = myjob.rfjson
				if (myjob.rfjson.schedule && myjob.rfjson.schedule.length > 0) {
					let largestRfWeekNum = 1
					if(myrfconfig.type === 'dayparts_standard' || myrfconfig.type === 'dayparts_full') {
						for(let st of myrfconfig.schedule) {
							for(let wk of st.spotsByWeek) {
								if(wk.weekNumber > largestRfWeekNum) largestRfWeekNum = wk.weekNumber
							}
						}
					}
					else {
						for (let item of myjob.rfjson.schedule) {
							//catch and update any old rfjson keys
							if(item.audio_type) {
								item.audioType = item.audio_type
								delete item.audio_type
							}
							if(item.stnid) {
								item.stationId = item.stnid
								delete item.stnid
							}
							if(item.play_date) {
								item.playDate = item.play_date
								delete item.play_date
							}
							if(item.play_time) {
								item.playTime = item.play_time
								delete item.play_time
							}
							let mywk = parseInt(item.weekNumber)
							if (mywk > largestRfWeekNum) largestRfWeekNum = mywk
						}
					}
					this.store.nweeksrf = largestRfWeekNum
					if(largestRfWeekNum < 4) this.store.precampaignWeeksShown = 4
					else this.store.precampaignWeeksShown = largestRfWeekNum
					if (!myrfconfig.ratecard) myrfconfig.ratecard = {}
					if (!myrfconfig.ratecard_id) myrfconfig.ratecard_id = null
					if (!myrfconfig.randomiseSpotTimesWithinCombos) myrfconfig.randomiseSpotTimesWithinCombos = false
				}
				let tempconfig = JSON.parse(JSON.stringify(myrfconfig)) //handle bug where week order gets out of whack str instead of int with 10<1 etc
				tempconfig.schedule.sort((a, b) => (a.weekNumber > b.weekNumber) ? 1 : -1)
				this.store.rfConfig = tempconfig
			}
			else {
				this.store.rfConfig = this.getDefaultRFConfig()
			}
			this.store.showGreenHighlights = null;
			this.store.activeTab = 'Results';
			this.store.jobManageOverlayShown = false;
		},
		delJob(jid) {
			let c = window.confirm("Are you sure you wish to delete this job?");
			if(c !== false && this.fetching === false) {
				this.fetching = true;
				let self = this;
				axios.post(
					"/post/job-delete.php",
					JSON.stringify({
						usercreds: self.store.usercreds,
						jid: jid,
					})
				).then(function (response) {
					let ret = response.data //response.data is returned info
					if (ret.resp) {
						self.showKalert(ret.resp)
						if(ret.resp.jobid > 0 && self.store.jobid === ret.resp.jobid) { //current active job has been deleted, reset window var
							self.store.jobid = 0;
						}
					}
					self.fetching = false;
					self.fetchJobList(); //refresh the job list
				}).catch(function (error) {
					console.log(error);
					self.fetching = false;
				});
			}
		},
		changeJobName(ob) {
			let jid = ob.jid
			let newname = ob.newname
			let j = this.store.jobs.findIndex(item => item.id === jid)
			if(j >= 0) {
				this.store.jobs[j].name = newname
			}
		},
	},
	watch: {},
	mounted() {
		this.fetchJobList();
	}
}
</script>

<style scoped>
.popup-container {
	width: 1000px;
}
.inside-tabs {
	display: flex;
}
.inside-tabs .tab {
	padding: 5px 15px;
	border: 1px solid var(--greymid);
	cursor: pointer;
	font-size: 12px;
	font-weight: bold;
}
.inside-tabs .tab.active {
	background-color: var(--greymid);
}
</style>
<template>
	<div>
		<QueryErrorNote v-if="store.lookupQueryFailed === true"></QueryErrorNote>
		<div v-else>
			<div class="" style="display: flex; gap: 20px;">
				<div v-for="tname in table_items" :key="tname">
					<p style="margin-bottom: 5px; font-size: 12px; font-weight: bold;">{{tname.name}}</p>
					<select v-model="table_selections[tname.id]" class="selectbox">
						<option v-for="(opt, ind) in filteritems[tname.id]" :key="ind" :value="opt.id">{{getFilterItemOptName(tname.id, opt)}}</option>
					</select>
				</div>
				<div>
					<p style="margin-bottom: 5px; font-size: 12px; font-weight: bold;">Show Cume In</p>
					<select v-model="store.viewPreferences.cumePrecisionLevel" class="selectbox">
						<option :value="1">1s</option>
						<option :value="100">100s</option>
						<option :value="1000">1000s</option>
					</select>
				</div>
<!--				<div>-->
<!--					<p style="margin-bottom: 5px; font-size: 12px; font-weight: bold;">Commas</p>-->
<!--					<select v-model="store.viewPreferences.commasInData" class="selectbox">-->
<!--						<option :value="true">Yes</option>-->
<!--						<option :value="false">No</option>-->
<!--					</select>-->
<!--				</div>-->
				<div>
					<p style="margin-bottom: 5px; font-size: 12px; font-weight: bold; width: 70px;">Mkt Display</p>
					<select v-model="store.viewPreferences.showGroupedMarket" class="selectbox">
						<option :value="true">Group & Individual</option>
						<option :value="false">Individual Only</option>
						<option value="grouponly">Group Only</option>
					</select>
				</div>
			</div>

			<WarningsBox v-if="hasMetroAndRegionalCombination && table_selections.markets === 0"
					:warnings="['Grouped Markets should be viewed with caution when GFK Metro markets are run with GFK Regional or Xtra Regional markets.  ' +
					'Share, Average Audience are not available for combined GFK Metro & GFK Regional Markets.  Only GFK Metro (SMBAP) can be run as a group for Share.' +
					'Station Listened To Most for Xtra markets can only be run at single market level. ' +
					'Grouped Market Cume is calculated at market level, and summarised here for efficiency purposes only.']"
			></WarningsBox>

			<WarningsBox v-if="store.returnedWarnings && store.returnedWarnings.length > 0" :warnings="store.returnedWarnings"></WarningsBox>

			<div style="margin-top: 20px;">
				<div class="primary-button" @click="tableToClipboard()" v-if="isExporting === false">
					<i class="mdi mdi-file-excel-outline"></i>
					Copy This Table To Clipboard
				</div>
				<div class="primary-button" @click="exportAllToExcel(true)" v-if="isExporting === false" style="margin-left: 10px;">
					<i class="mdi mdi-file-excel-outline"></i>
					Export This Table To Excel
				</div>
				<div class="primary-button" @click="exportAllToExcel()" v-if="isExporting === false" style="margin-left: 10px;">
					<i class="mdi mdi-file-excel-outline"></i>
					Export All Tables To Excel
				</div>
			</div>

			<div id="excel-wrapper" :class="{hiddend : isExporting}">
				<div v-for="(selections, ind) in tableIterations" :key="ind">
					<ResultTableComplexSingle v-if="ind === 0 || isExporting"
						:filteritems="filteritems"
						:table_selections="selections"
					></ResultTableComplexSingle>
				</div>
			</div>
			<div v-if="isExporting === true" style="padding: 50px 0;">
				<p>Exporting, please wait</p>
			</div>

			<ColourHighlightToggler v-if="isExporting === false"></ColourHighlightToggler>
		</div>
	</div>
</template>

<script>
import {store} from '@/store.js'
import {globalMixin} from "@/mixins"
import WarningsBox from "@/components/result-tables/WarningsBox";
import ColourHighlightToggler from "@/components/result-tables/ColourHighlightToggler";
import TableToExcel from "@linways/table-to-excel";
import ResultTableComplexSingle from "@/components/result-tables/ResultTableComplexSingle";
import QueryErrorNote from "@/components/QueryErrorNote.vue";
export default {
	name: "ResultTableGroup",
	components: {
		QueryErrorNote,
		ResultTableComplexSingle,
		ColourHighlightToggler,
		WarningsBox

	},
	mixins: [globalMixin],
	props: {

	},
	data: function () {
		return {
			store: store,

			table_selections: {
				markets: null,
				surveys: null,
				stations: null,
				dayparts: null,
				statistics: null,
				demographics: null,
				places: 0,
				qtrHrs: 0,
				audioTypes: 0,
			},

			isExporting: false,
			excelbook: null,

		}
	},
	computed: {
		tableIterations() {
			let orig = JSON.parse(JSON.stringify(this.table_selections))
			let arr = []
			arr.push(orig)
			let keys = []
			keys.push(JSON.stringify(orig))
			let tobnames = []
			let tmaps = {}
			for(let ti of this.table_items) {
				let tobname = ti.id //eg surveys, markets, dayparts
				tobnames.push(tobname)
				if(!tmaps[tobname]) tmaps[tobname] = []
				let myitems = this.filteritems[tobname]
				for(let myi of myitems) {
					if (tobname === 'audioTypes' && myi.id === 9) continue
					tmaps[tobname].push(myi.id)
				}
			}
			let itemtypes = ['audioTypes', 'dayparts', 'demographics', 'markets', 'places',
				'qtrHrs', 'stations', 'statistics', 'surveys']
			for(let it of itemtypes) {
				if(!tmaps[it]) tmaps[it] = [orig[it]]
			}
			for(let i=0; i<tmaps.audioTypes.length; i++) {
				for(let j=0; j<tmaps.dayparts.length; j++) {
					for(let k=0; k<tmaps.demographics.length; k++) {
						for(let l=0; l<tmaps.markets.length; l++) {
							for(let l=0; l<tmaps.markets.length; l++) {
								for(let m=0; m<tmaps.places.length; m++) {
									for(let n=0; n<tmaps.qtrHrs.length; n++) {
										for(let o=0; o<tmaps.stations.length; o++) {
											for(let p=0; p<tmaps.statistics.length; p++) {
												for(let q=0; q<tmaps.surveys.length; q++) {
													let temp = JSON.parse(JSON.stringify(orig))
													temp.audioTypes = tmaps.audioTypes[i]
													temp.dayparts = tmaps.dayparts[j]
													temp.demographics = tmaps.demographics[k]
													temp.markets = tmaps.markets[l]
													temp.places = tmaps.places[m]
													temp.qtrHrs = tmaps.qtrHrs[n]
													temp.stations = tmaps.stations[o]
													temp.statistics = tmaps.statistics[p]
													temp.surveys = tmaps.surveys[q]
													let mykey = JSON.stringify(temp)
													if(!keys.includes(mykey)) {
														arr.push(temp)
														keys.push(mykey)
													}
												}
											}
										}
									}
								}
							}
						}
					}
				}
			}
			return arr
		},
		table_items() {
			return this.store.tableLayout.tables
		},
		column_items() {
			return this.store.tableLayout.columns
		},
		row_items() {
			return this.store.tableLayout.rows
		},
		marketFilterItems() {
			let ma = []
			if(this.store.returnedData.marketIds) {
				let rd = this.store.returnedData.marketIds
				if (rd.includes(0) && this.store.viewPreferences.showGroupedMarket !== false) {
					ma.push({id: 0, name: 'Grouped Markets'})
				}
				if (rd.includes(990) && this.store.viewPreferences.showGroupedMarket !== false) {
					ma.push({id: 990, name: 'Grouped SMBAP'})
				}
				if (rd.includes(991) && this.store.viewPreferences.showGroupedMarket !== false) {
					ma.push({id: 991, name: 'Grouped GFK non-SMBAP'})
				}
				if (rd.includes(992) && this.store.viewPreferences.showGroupedMarket !== false) {
					ma.push({id: 992, name: 'Grouped Xtra'})
				}
				if(this.store.viewPreferences.showGroupedMarket !== 'grouponly') {
					for (let mid of rd) {
						if (mid > 0 && mid < 990) {
							let ob = this.store.markets.find(item => item.id === mid)
							if (ob) ma.push(ob)
						}
					}
				}
			}
			return ma
		},
		filteritems() {
			return {
				markets: this.marketFilterItems,
				surveys: this.store.selectionObs.surveys,
				stations: this.store.selectionObs.stations,
				dayparts: this.store.selectionObs.dayparts,
				statistics: this.store.selectionObs.statistics,
				demographics: this.store.selectionObs.demographics,
				places: this.store.selectionObs.places,
				qtrHrs: this.store.selectionObs.qtrHrs,
				audioTypes: this.store.selectionObs.audioTypes,
			}
		},
		hasMetroAndRegionalCombination() {
			let hasMetro = false //SMBAP only - not GC, NEW, CBR
			let hasRegional = false
			for(let m of this.store.selectionObs.markets) {
				if(m.isGfk === true && this.store.gfkMetroMktIds.includes(m.id)) hasMetro = true
				else hasRegional = true
			}
			if(hasMetro === true && hasRegional === true) return true
			return false
		},
	},
	methods: {
		tableToClipboard() {
			let c = ''
			for(let item of document.querySelectorAll(".excel-table-holder")) {
				let contentT = item.cloneNode(true)
				c = contentT.innerHTML.replace(/,/g,'')
				navigator.clipboard.writeText(c)
				break //just the first one
			}
		},
		getNewSheetName(name, iteration, usedNames) {
			let it_str = iteration.toString()
			if (iteration < 10) it_str = '0' + iteration.toString()
			let newname = name + '_' + it_str
			if(usedNames.includes(newname)) {
				return this.getNewSheetName(name, iteration+1, usedNames)
			}
			return newname
		},
		exportAllToExcel(singleOnly = false) {
			this.store.showLoader = true
			if(singleOnly === false) this.isExporting = true //if exporting all then show all tables briefly
			this.excelbook = null
			let prevCommaSetting = this.store.viewPreferences.commasInData
			this.store.viewPreferences.commasInData = false //fix issue with cumes in Excel with commas
			let self = this
			let count = 1
			setTimeout(function() {
				let sheetnamesUsed = []
				for(let item of document.querySelectorAll(".excel-table-holder")) {
					let contentT = item.cloneNode(true)
					contentT.innerHTML = contentT.innerHTML.replace(/,/g,'')
					let name = item.id
					name = name.replace("tbl_","")
					if(name.length === 0) name = 'Sheet '+count
					name = name.slice(0,28)
					if(sheetnamesUsed.includes(name)) {
						name = self.getNewSheetName(name, 1, sheetnamesUsed)
					}
					sheetnamesUsed.push(name)
					if(self.excelbook === null) {
						self.excelbook = TableToExcel.tableToBook(contentT, {sheet:{name: name}})
					}
					else {
						TableToExcel.tableToSheet(self.excelbook, contentT, {sheet:{name: name}})
					}
					count++
				}
				let filename = "Audology Insights Export.xlsx"
				if(self.store.jobname && self.store.jobname.length > 0) {
					filename = self.store.jobname + ".xlsx"
				}
				TableToExcel.save(this.excelbook, filename)
				self.isExporting = false
				self.store.showLoader = false
				self.store.viewPreferences.commasInData = prevCommaSetting
			}.bind(this), 500)
		},
		defaultTableSelections() {
			if(!this.table_selections.markets && this.store.selectionObs.markets.length > 0) {
				this.table_selections.markets = this.store.selectionObs.markets[0].id
				setTimeout(function() {
					if(this.marketFilterItems.find(item => item.id === 0) && this.store.viewPreferences.showGroupedMarket !== false) {
						this.table_selections.markets = 0
					}
				}.bind(this), 50)
			}
			if(!this.table_selections.surveys && this.store.selectionObs.surveys.length > 0) this.table_selections.surveys = this.store.selectionObs.surveys[0].id
			if(!this.table_selections.stations && this.store.selectionObs.stations.length > 0) this.table_selections.stations = this.store.selectionObs.stations[0].id
			if(!this.table_selections.dayparts && this.store.selectionObs.dayparts.length > 0) this.table_selections.dayparts = this.store.selectionObs.dayparts[0].id
			if(!this.table_selections.statistics && this.store.selectionObs.statistics.length > 0) this.table_selections.statistics = this.store.selectionObs.statistics[0].id
			if(!this.table_selections.demographics && this.store.selectionObs.demographics.length > 0) this.table_selections.demographics = this.store.selectionObs.demographics[0].id
			if(!this.table_selections.places) this.table_selections.places = 0
			if(!this.table_selections.qtrHrs) this.table_selections.qtrHrs = 0
			if(!this.table_selections.audioTypes) this.table_selections.audioTypes = 0
		},
	},
	watch: {
		returnedData() {
			this.defaultTableSelections()
		},
		'store.tableLayout': {
			deep: true,
			handler() {
				this.refreshColourHighlights()
				if(this.store.tableLayout.rows.find(item => item.id === 'markets') || this.store.tableLayout.columns.find(item => item.id === 'markets')) {
					if(this.filteritems.markets.find(item => item.id === 0)) this.table_selections.markets = 0
					else this.table_selections.markets = this.filteritems.markets[0].id
				}
			}
		},
		table_selections: {
			deep: true,
			handler() {
				this.refreshColourHighlights()
			}
		},
		'store.viewPreferences': {
			deep: true,
			handler() {
				if(this.store.viewPreferences.showGroupedMarket === false && this.table_selections.markets === 0) {
					this.table_selections.markets = this.store.selectionObs.markets[0].id
				}
				else if(this.store.viewPreferences.showGroupedMarket === 'grouponly') {
					this.table_selections.markets = 0
				}
			}
		},
	},
	mounted() {
		this.defaultTableSelections()
	}
}
</script>

<style scoped>
.std-tbl td {
	border: 1px solid var(--greymid);
	padding: 5px
}
.hidden {
	display: none;
}
</style>